import React, { useCallback, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHttpClient } from "../shared/hooks/http-hook";
import { HelmetProvider } from "react-helmet-async";
import { Helmet } from "react-helmet";
import Header from "../shared/UIElements/Header";
import HeadTags from "../shared/UIElements/HeadTags";
import { AuthContext } from "../shared/context/auth-context";
import { useForm } from "react-hook-form";
import LoadingSpinner from "../shared/UIElements/LoadingSpinner";
import ErrorModal from "../shared/UIElements/ErrorModal";
import Tags from "./Tags";
const StoryClick = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [theStory, setTheStory] = useState();

  const storyId = useParams().storyId;

  const auth = useContext(AuthContext);

  const { register, handleSubmit } = useForm();

  const [storyName, setStoryName] = useState();

  const [about, setAbout] = useState();

  const fetchAStory = useCallback(async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/general/getSingleStory/${storyId}`
      );
      console.log(responseData);
      setStoryName(responseData.findCategory);
      setTheStory(responseData.findStory);

      setAbout(responseData.findAdmin);
    } catch (err) {}
    // try {
    //   await sendRequest(`http://localhost:5000/`);
    //   console.log("complete");
    // } catch (err) {}
  }, [storyId, sendRequest]);

  useEffect(() => {
    fetchAStory();
  }, [fetchAStory]);

  const [tags, setTags] = useState();

  const [newTag, setNewTag] = useState();

  const getTags = useCallback(async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/general/getTags/${storyId}`
      );

      setTags(responseData.findTags);
    } catch (err) {}
  }, [storyId, sendRequest]);

  useEffect(() => {
    // const getTags = async () => {
    //   try {
    //     const responseData = await sendRequest(
    //       `${process.env.REACT_APP_BACKEND_URL}/general/getTags/${storyId}`
    //     );
    //     console.log(responseData);

    //     setTags(responseData.findTags);
    //   } catch (err) {}
    // };
    getTags();
  }, [getTags]);

  const submitATag = async (data) => {
    try {
      sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/addATag/${storyId}/${data.tagYo}`,
        "PUT",
        null,

        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.adminToken,
        }
      );
    } catch (err) {}

    // console.log(newTag);

    const getTags = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/general/getTags/${storyId}`
        );
        //console.log(responseData);

        setTags(responseData.findTags);
      } catch (err) {}
    };

    setTimeout(getTags, 500);
    //getTags();
  };

  const submitATagAsGuest = () => {};

  return (
    <div>
      {isLoading && <LoadingSpinner asOverlay />}

      {<ErrorModal onClear={clearError} error={error} />}
      {theStory && (
        <div className="homePage-centerStory">
          <Helmet>
            <meta property="og:title" content={theStory.headline} />
            <meta
              property="og:image"
              content={`https://s3.us-east-1.amazonaws.com/thejournalofpress/${theStory.coverPhoto}`}
            />
            <meta property="og:image:width" content="1200px" />
            <meta property="og:image:height" content="630px" />
          </Helmet>
          <div className="homePage-centerStory-imgWrapper">
            {" "}
            <img
              className="homePage-centerStory-img"
              src={`https://s3.us-east-1.amazonaws.com/thejournalofpress/${theStory.coverPhoto}`}
              alt="The Journal of Press"
            />{" "}
          </div>

          <div className="homePage-centerStory-headline">
            {theStory.headline}
          </div>
          <div>
            {theStory.story.map((y) =>
              y.title ? (
                <div className="homePage-centerStory-title"> {y.words} </div>
              ) : y.paragraph ? (
                <div className="homePage-centerStory-paragraph">
                  {" "}
                  {y.words}{" "}
                </div>
              ) : y.code ? (
                <div className="homePage-centerStory-code"> {y.words} </div>
              ) : y.reel ? (
                <video className="homePage-centerStory-img" controls>
                  {console.log(y.words)}
                  <source
                    className="homePage-centerStory-img"
                    src={`https://s3.us-east-1.amazonaws.com/thejournalofpress/${y.words}`}
                    type="video/mp4"
                  />
                </video>
              ) : (
                <div className="homePage-centerStory-imgWrapper">
                  {" "}
                  <img
                    className="homePage-centerStory-img"
                    src={`https://s3.us-east-1.amazonaws.com/thejournalofpress/${y.words}`}
                    alt="The Journal of Press"
                  />{" "}
                </div>
              )
            )}
          </div>
          <br />
          <div className="tags-box">
            tags
            {!isLoading && tags && (
              <div>
                {tags.map((x) => (
                  <div className="tags">{x.tag}</div>
                ))}
              </div>
            )}
            {auth.adminToken && (
              <div>
                <form onSubmit={handleSubmit(submitATag)}>
                  <label>add tag</label>
                  <input {...register("tagYo")} />
                  <button>submit</button>
                </form>
              </div>
            )}
            {storyName && (
              <div>
                <div className="homePage-centerStory-category">
                  {storyName.name}
                </div>
                <div className="homePage-centerStory-category">
                  {theStory.date}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default StoryClick;
