import { useCallback, useRef } from "react";

export const useAnimationHook = () => {
  const startHook = (animationRef, animation) => {
    if (animationRef.current != null) {
      const observer = new IntersectionObserver((entrys) => {
        entrys.forEach((entry) => {
          if (entry.isIntersecting) {
            animationRef.current.classList.add(animation);
            return;
          }
        });
      });

      observer.observe(animationRef.current);
    }
  };

  return { startHook };
};
