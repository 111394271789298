import React, { useContext } from "react";
import { useHttpClient } from "../hooks/http-hook";

import logo from "../../imgs/jop-logo.jpg";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/auth-context";

import { IoAddCircleOutline } from "react-icons/io5";

import blueStar from "../../imgs/blueStar.png";

import redStar from "../../imgs/redStar.png";
const NavBar = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const auth = useContext(AuthContext);

  const resetFunction = () => {
    auth.setBackHome("home");
  };

  return (
    <div>
      <div className="navBar">
        <img src={redStar} className="navBar-stars" alt="start" />
        <img src={blueStar} className="navBar-stars" alt="start" />
        {/* {!auth.adminToken && (
          <Link to={"/"}>
            <img
              onClick={() => resetFunction()}
              // onClick={auth.setBackHome("home")}
              src={logo}
              alt="The Journal of Press"
              className="navBar-logo"
            />
          </Link>
        )} */}

        <img
          //onClick={() => resetFunction()}
          // onClick={auth.setBackHome("home")}
          src={logo}
          alt="The Journal of Press"
          className="navBar-logo"
        />

        <img src={redStar} className="navBar-stars" alt="start" />
        <img src={blueStar} className="navBar-stars" alt="start" />
        {auth.adminToken && (
          //onClick={auth.setBackHome("home")}

          <Link to={"/journal"}>
            <img
              onClick={() => resetFunction()}
              src={logo}
              alt="The Journal of Press"
              className="navBar-logo"
            />
          </Link>
        )}
        {auth.adminToken && (
          <Link to={"/"}>
            <div className="navBar-ceo-nav">
              <IoAddCircleOutline />
            </div>
          </Link>
        )}
      </div>
      <div className="navBar-bottom"></div>

      {props.children}
    </div>
  );
};

export default NavBar;
