import React from "react";
import { Helmet } from "react-helmet";

import PropTypes from "prop-types";

const HeadTags = (props) => {
  return (
    <div>
      <Helmet>
        <meta property="og:title" content={props.headline} />
        <meta
          property="og:image"
          content={`https://s3.us-east-1.amazonaws.com/thejournalofpress/${props.coverPhoto}`}
        />
      </Helmet>
    </div>
  );
};

HeadTags.propTypes = {
  data: PropTypes.object,
  facebook: PropTypes.object.isRequired,
};

export default HeadTags;
