import React, { useState } from "react";
import CategoryCreator from "./components/CategoryCreator";
import StorySubmital from "./components/StorySubmital";

const PressDash = () => {
  const [island, setIsland] = useState("story");

  const switchIsland = (key) => {
    if (key.target.id === "story") {
      setIsland("story");
    } else if (key.target.id === "category") {
      setIsland("category");
    }
  };

  return (
    <div>
      <div className="press-choose">
        <div className="press-choose-titles" id="story" onClick={switchIsland}>
          story
        </div>
        <div
          className="press-choose-titles"
          id="category"
          onClick={switchIsland}
        >
          categories
        </div>
      </div>

      {island === "category" && <CategoryCreator />}
      {island === "story" && <StorySubmital />}
    </div>
  );
};

export default PressDash;
