import React, { useCallback, useContext, useEffect, useState } from "react";

import { useHttpClient } from "../../shared/hooks/http-hook";

import { useFieldArray, useForm } from "react-hook-form";
import { AuthContext } from "../../shared/context/auth-context";
import LoadingSpinner from "../../shared/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/UIElements/ErrorModal";

const CategoryCreator = () => {
  const auth = useContext(AuthContext);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const { register, control, handleSubmit, reset, watch, setValue } = useForm({
    // defaultValues: {
    //   hostArray: [
    //     {
    //       //title: "",
    //       paragraph: "",
    //       //picture: null,
    //     },
    //   ],
    //   //   imggrab: [
    //   //     {
    //   //       img: null,
    //   //     },
    //   //   ],
    // },
  });

  const [categories, setCategories] = useState();

  const [categoryTitle, setCategoryTitle] = useState();

  const [categoryId, setCategoryId] = useState();

  const [aCategory, setACategory] = useState();

  const [subcat, setSubcat] = useState();

  const fetchCategories = useCallback(async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getCategories`
      );

      setCategories(responseData.findCategories);
    } catch (err) {
      console.log(err);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const [clickedCat, setClickedCat] = useState();

  const parseCategories = async (key) => {
    setClickedCat(key.target.id);
  };

  const getSubcat = (key) => {
    console.log(key.target.id);
    setSubcat(key.target.id);
    //setSubcat2(key.target.id);
  };

  const [toggleCatOrSub, setToggleCatOrSub] = useState();

  const subOrCat = (key) => {
    if (key.target.id === "subcategory") {
      setToggleCatOrSub("subcategory");
    } else if (key.target.id === "category") {
      setToggleCatOrSub("category");
    }
  };

  const submitNewCategory = async (data) => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/createACategory`,
        "POST",
        JSON.stringify({
          name: data.name,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.adminToken,
        }
      );
    } catch (err) {}
  };

  const submitNewSubCategory = async (data) => {
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/createSubcategory`,
        "POST",
        JSON.stringify({
          category: clickedCat,
          subcategory: data.subcategory,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.adminToken,
        }
      );
    } catch (err) {}
  };

  return (
    <div className="categoryCreation">
      {isLoading && <LoadingSpinner asOverlay />}

      {<ErrorModal onClear={clearError} error={error} />}
      <div>
        <div id="category" onClick={subOrCat}>
          category
        </div>
        <div id="subcategory" onClick={subOrCat}>
          subcategory
        </div>

        {toggleCatOrSub === "category" && (
          <div>
            <div>
              <form onSubmit={handleSubmit(submitNewCategory)}>
                <input {...register(`name`)} />
                <button>submit</button>
              </form>
            </div>
          </div>
        )}

        {categories && toggleCatOrSub === "subcategory" && (
          <div>
            <div>
              <div className="homePage-categories-bar">
                {categories.map((x) => (
                  <div
                    id={x.name}
                    onClick={parseCategories}
                    className="homePage-categories-title"
                  >
                    {x.name}
                  </div>
                ))}
              </div>
              {/* {aCategory && (
              <div className="homePage-categories-bar2">
                {aCategory.subcategories.map((x) => (
                  <div
                    onClick={getSubcat}
                    id={x.name}
                    className="homePage-categories-title2"
                  >
                    {x.name}
                  </div>
                ))}
              </div>
            )} */}
            </div>

            <div>
              <form onSubmit={handleSubmit(submitNewSubCategory)}>
                <label>category</label>
                <input value={clickedCat} {...register(`category`)} />
                <br />
                <label>subcategory</label>
                <input {...register(`subcategory`)} />
                <button>submit</button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryCreator;
