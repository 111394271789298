import { createContext } from "react";

export const AuthContext = createContext({
  //admin
  adminIsLoggedIn: false,
  adminId: null,
  adminToken: null,
  adminLogin: () => {},
  adminLogout: () => {},
  //admin

  //employee

  employeeIsLoggedIn: false,
  employeeId: null,
  employeeToken: null,
  employeeLogin: () => {},
  employeeLogout: () => {},

  //employee

  //customer

  customerIsLoggedIn: false,
  customerId: null,
  customerToken: null,
  customerLogin: () => {},
  customerLogout: () => {},

  //customer

  backHome: null,
  setBackHome: () => {},
});
