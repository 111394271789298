import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import logo from "../imgs/jop-logo.jpg";

import adpic from "../imgs/adsjop.jpg";
import cloverSoftwareLogo from "../imgs/cloverLogo.png";
import { Link } from "react-router-dom";

import { useHttpClient } from "../shared/hooks/http-hook";
import { useAnimationHook } from "../shared/hooks/animation-hook";
import StoryItem from "./StoryItem";
import { Helmet } from "react-helmet";

import joplogo from "../imgs/jop-logo.jpg";
import { AuthContext } from "../shared/context/auth-context";
import { IoChevronForwardCircleOutline } from "react-icons/io5";
import { useForm } from "react-hook-form";
import CategoryList from "./search/CategoryList";
import SubcategoryList from "./search/SubcategoryList";
import TagList from "./search/TagList";
import CollectiveStoriesList from "./search/CollectiveStoriesList";

const HomePage = () => {
  const { startHook } = useAnimationHook();

  const { register, handleSubmit, reset } = useForm();

  const auth = useContext(AuthContext);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [stories, setStories] = useState();

  const [generalStories, setGeneralStories] = useState();

  const bottomRef = useRef(null);

  useEffect(() => {
    startHook(bottomRef, "animationTop");
  }, [startHook]);

  const [views, setViews] = useState();

  const fetchStories = useCallback(async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/general/getStories`
      );
      console.log(responseData);
      setGeneralStories(responseData.findStories);
      setStories(responseData.findStories);
      setViews(responseData.getGeos);
    } catch (err) {
      console.log(err);
    }
  }, [sendRequest]);

  useEffect(() => {
    if (auth.backHome === "home") {
      fetchStories();
    }
  }, [fetchStories, auth.backHome]);

  useEffect(() => {
    //console.log("hello");
    fetchStories();
  }, [fetchStories]);

  const [aCategory, setACategory] = useState();

  const [categoryStories, setCategoryStories] = useState();

  const parseCategories = async (key) => {
    //console.log(key.target.id);

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getSingleCategory/${key.target.id}`
      );
      setACategory(responseData.findCategory);
      setStories(responseData.findStories);
      setViews(responseData.getGeos);
      console.log(responseData);
      auth.setBackHome("");
    } catch (err) {}
  };

  const getSubcategoriesFunc = async (key) => {
    console.log(key.target.id);
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getSubcategoryList/${aCategory.name}/${key.target.id}`
      );
      console.log(responseData);
      setStories(responseData.findStories);
    } catch (err) {}
  };

  //const height

  //console.log(height);

  const [categories, setCategories] = useState();

  const fetchCategories = useCallback(async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getCategories`
      );
      //console.log(responseData);

      setCategories(responseData.findCategories);
    } catch (err) {
      console.log(err);
    }
  }, [sendRequest]);

  useEffect(() => {
    // const fetchCategories = async () => {
    //   try {
    //     const responseData = await sendRequest(
    //       `${process.env.REACT_APP_BACKEND_URL}/admin/getCategories`
    //     );
    //     console.log(responseData);

    //     setCategories(responseData.findCategories);
    //   } catch (err) {
    //     console.log(err);
    //   }
    fetchCategories();
  }, [fetchCategories]);

  const [categoriesSearch, setCategoriesSearch] = useState();

  const [subcategoriesSearch, setSubcategoriesSearch] = useState();
  const [tagsSearch, setTagsSearch] = useState();

  const [tab, setTab] = useState("home");

  const [searchBulk, setSearchBulk] = useState();
  const [searchId, setSearchId] = useState();

  const searchItems = async (data) => {
    //auth.setBackHome("");
    console.log(auth.backHome);
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/general/broadSearch/${data.search}`
      );
      //console.log(responseData);
      auth.setBackHome("");
      setTab("search");
      setSearchId("all");
      setSearchBulk(responseData.collectiveStories);

      setTagsSearch(responseData.findTagsStories);
      setSubcategoriesSearch(responseData.findSubcategoriesStories);
      setCategoriesSearch(responseData.findCategoriesStories);
    } catch (err) {}
  };

  const searchIdFunction = (key) => {
    if (key.target.id === "categories") {
      setSearchId("categories");
    } else if (key.target.id === "subcategories") {
      setSearchId("subcategories");
    } else if (key.target.id === "tags") {
      setSearchId("tags");
    }
  };

  //     fetchCategories();
  //   }, [categories, sendRequest]);

  return (
    <div className="homePage">
      {/* <div className="homePage-navBar">
        <img
          src={logo}
          alt="The Journal of Press"
          className="homePage-navBar-logo"
        />
        <p className="homePage-navBar-logo">
          The Journal
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; of Press
        </p>
      </div> */}
      {/* <Link to={{ pathname: "cloversoftwarecompany.com" }} target="_blank"> */}
      <Helmet>
        <meta property="og:title" content={"The Journal of Press"} />
        <meta property="title" content={"The Journal of Press"} />
        <meta property="description" content={"Amazing Articles"} />
        <meta property="image" content={joplogo} />
        <meta property="og:image" content={joplogo} />
        <meta property="og:image:width" content="1200px" />
        <meta property="og:image:height" content="630px" />
      </Helmet>

      <div>
        {
          // border one
        }
        <div>
          {
            //border two
          }
          <div className="homePage-incomingSite2">
            <div className="homePage-incomingSite">
              {
                // message
              }
              Hey guyz , it’s Zeus … we’re going to be starting with articles
              pretty soon. We’re really excited. We’re going to allow people who
              are euntrapeuners philanthropists models to write their own
              articles . We’re going to have articles on models , cars ,
              business , US LAW , INTERNATIONAL LAW , art , popular science .
              Zeus is really busy and he’s a perfectionist . This is going to
              come out perfect . One of our missions is to preserve free speech,
              and we operate off the idea that to take away free speech is to
              harm the American home, and the American family.
              <br />
              <div className="textalignLeft"> - Zeus </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="homePage-hp-ad">
        <div>
          <a
            href="https://www.cloversoftwarecompany.com"
            rel="noreferrer"
            target="_blank"
            className="atag"
          >
            <div className="homePage-hp-ad-adIcon">ad</div>
            <div className="homePage-hp-ad-img">
              <img
                className="homePage-hp-ad-img"
                src={adpic}
                alt="Clover Software"
              />
            </div>
            <div className="inlineBlock homePage-hp-ad-textBlock">
  
              <div className="homePage-hp-ad-description margin2">
                PERSONALIZED ADS COMING SOON 50¢ a click per article per person
              </div>
      
            </div>
          </a>
        </div>
      </div> */}

      {/* <div className="homePage-search">
        <form onSubmit={handleSubmit(searchItems)}>
          <label className="homePage-search-label">search:</label>
          <input
            className="homePage-search-input"
            placeholder="tags, categories, subcategories"
            {...register("search")}
          />
          <div className="homePage-search-container">
            <button className="homePage-search-button">
              <IoChevronForwardCircleOutline />
            </button>
          </div>
        </form>
      </div> */}

      {/* {views && <div className="homePage-views">views: {views.length}</div>} */}
      {/* <div className="homePage-categories"> */}
      {(tab === "home" || auth.backHome === "home") && (
        <div>
          {categories && (
            <div>
              {/* <div className="homePage-categories-bar">
                {categories.map((x) => (
                  <div
                    id={x.name}
                    onClick={parseCategories}
                    className="homePage-categories-title"
                  >
                    {x.name}
                  </div>
                ))}
              </div> */}

              {/* {aCategory && (
                <div className="homePage-categories-bar2">
                  {aCategory.subcategories.map((x) => (
                    <div
                      id={x.name}
                      onClick={getSubcategoriesFunc}
                      className="homePage-categories-title2"
                    >
                      {x.name}
                    </div>
                  ))}
                </div>
              )} */}
            </div>
          )}
          {/* </div> */}

          {/* {stories && <StoryItem stories={stories} />} */}
        </div>
      )}

      {/* {tab === "search" && auth.backHome !== "home" && (
        <div>
          {console.log(auth.backHome)}
          <div className="homePage-categories-bar">
            <div
              className="homePage-categories-title"
              onClick={searchIdFunction}
              id="categories"
            >
              Categories
            </div>
            <div
              className="homePage-categories-title"
              onClick={searchIdFunction}
              id="subcategories"
            >
              Subcategories
            </div>
            <div
              className="homePage-categories-title"
              onClick={searchIdFunction}
              id="tags"
            >
              Tags
            </div>
          </div>

          {searchBulk && (searchId === "all" || searchId === null) && (
            <CollectiveStoriesList items={searchBulk} />
          )}

          {searchId === "categories" && (
            <CategoryList items={categoriesSearch} />
          )}
          {searchId === "subcategories" && (
            <SubcategoryList items={subcategoriesSearch} />
          )}
          {searchId === "tags" && <TagList items={tagsSearch} />}
        </div>
      )} */}
    </div>
  );
};

export default HomePage;
