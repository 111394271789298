import React from "react";
import { AuthContext } from "./shared/context/auth-context";
import { useAuth } from "./shared/hooks/auth-hook";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Routes,
} from "react-router-dom";

import HomePage from "./public/HomePage";

import "./css/style.css";
import Portal from "./press/Portal";
import NavBar from "./shared/UIElements/NavBar";
import PressDash from "./press/PressDash";
import StoryClick from "./public/StoryClick";

const App = () => {
  const {
    adminToken,
    adminLogin,
    adminLogout,
    adminId,
    employeeToken,
    employeeLogin,
    employeeLogout,
    employeeId,
    customerToken,
    customerLogin,
    customerLogout,
    customerId,
    backHome,
    setBackHome,
  } = useAuth();

  let theRoutes;

  if (!adminToken && !employeeToken && !customerToken) {
    theRoutes = (
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/house-login" element={<HouseEntrance />} /> */}
        <Route path="/house" element={<Portal />} />
        <Route path="/charta/:storyId" element={<StoryClick />} />

        <Route path="*" element={<HomePage />} />
      </Routes>
    );
  } else if (adminToken || employeeToken) {
    // theRoutes = (
    //   <Routes>
    //     <Route path="/" element={<HomePage />} />
    //     <Route path="*" element={<Dashboard />} />
    //   </Routes>
    // );
    theRoutes = (
      <Routes>
        <Route path="/" element={<PressDash />} />
        {/* <Route path="/house-login" element={<HouseEntrance />} /> */}
        <Route path="/journal" element={<HomePage />} />
        <Route path="/charta/:storyId" element={<StoryClick />} />
        <Route path="*" element={<PressDash />} />
      </Routes>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        adminIsLoggedIn: !!adminToken,
        adminToken: adminToken,
        adminId: adminId,
        adminLogin: adminLogin,
        adminLogout: adminLogout,
        employeeIsLoggedIn: !!employeeToken,
        employeeToken: employeeToken,
        employeeId: employeeId,
        employeeLogin: employeeLogin,
        employeeLogout: employeeLogout,
        customerIsLoggedIn: !!customerToken,
        customerToken: customerToken,
        customerId: customerId,
        customerLogin: customerLogin,
        customerLogout: customerLogout,
        backHome: backHome,
        setBackHome: setBackHome,
      }}
    >
      <Router>
        <NavBar>{theRoutes}</NavBar>
      </Router>
    </AuthContext.Provider>
  );
};

export default App;
