import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAnimationHook } from "../../shared/hooks/animation-hook";

import { useParams } from "react-router";
import { useHttpClient } from "../../shared/hooks/http-hook";

const CategoryItem = (props) => {
  const { startHook } = useAnimationHook();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const bottomRef = useRef(null);
  useEffect(() => {
    startHook(bottomRef, "animationTop");
  }, [startHook]);

  const [random, setRandom] = useState();

  useEffect(() => {
    const aNumber = Math.random() * 12 + 36.5;

    setRandom(aNumber);
    //console.log(props);
  }, [props]);

  const storyId = useParams().storyId;

  const [tags, setTags] = useState();

  const getTags = useCallback(async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/general/getTags/${props._id}`
      );

      setTags(responseData.findTags);
    } catch (err) {}
  }, [props._id, sendRequest]);

  useEffect(() => {
    getTags();
  }, [getTags]);

  return (
    <div>
      {" "}
      <Link to={`/charta/${props._id}`}>
        <div key={props._id} ref={bottomRef}>
          <div className="homePage-aStory" style={{ height: `${random}rem` }}>
            <div className="homePage-aStory-imgWrapper">
              {" "}
              <img
                className="homePage-aStory-img"
                src={`https://s3.us-east-1.amazonaws.com/thejournalofpress/${props.coverPhoto}`}
                alt="The Journal of Press"
              />{" "}
            </div>
            <div className="homePage-aStory-headline">{props.headline}</div>
            <div>
              {props.story.map((y) =>
                y.title ? (
                  <div className="homePage-aStory-title"> {y.words} </div>
                ) : y.paragraph ? (
                  <div className="homePage-aStory-paragraph"> {y.words} </div>
                ) : y.code ? (
                  <div className="homePage-aStory-code"> {y.words} </div>
                ) : (
                  <div className="homePage-aStory-imgWrapper">
                    {" "}
                    <img
                      className="homePage-aStory-img"
                      src={`https://s3.us-east-1.amazonaws.com/thejournalofpress/${y.words}`}
                      alt="The Journal of Press"
                    />{" "}
                  </div>
                )
              )}
            </div>
            <br />
            <div>
              tags
              {tags && (
                <div>
                  {tags.map((x) => (
                    <div className="tags">{x.tag}</div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CategoryItem;
