import { useState, useCallback, useEffect } from "react";

let adminLogoutTimer;
let employeeLogoutTimer;
let customerLogoutTimer;

export const useAuth = () => {
  //admin

  const [adminToken, setAdminToken] = useState(false);
  const [adminTokenExpirationDate, setAdminTokenExpirationDate] = useState();
  const [adminId, setAdminId] = useState(false);

  const adminLogin = useCallback((uid, token, expirationDate) => {
    setAdminToken(token);
    setAdminId(uid);

    const adminTokenExpirationDate =
      expirationDate ||
      new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 10000);
    setAdminTokenExpirationDate(adminTokenExpirationDate);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        adminId: uid,
        adminToken: token,
        expiration: adminTokenExpirationDate.toISOString(),
      })
    );
  }, []);

  const adminLogout = useCallback(() => {
    setAdminToken(null);
    setAdminTokenExpirationDate(null);
    setAdminId(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    if (adminToken && adminTokenExpirationDate) {
      const remainingTime =
        adminTokenExpirationDate.getTime() - new Date().getTime();
      adminLogoutTimer = setTimeout(adminLogout, remainingTime);
    } else {
      clearTimeout(adminLogoutTimer);
    }
  }, [adminToken, adminLogout, adminTokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.adminToken &&
      new Date(storedData.expiration) > new Date()
    ) {
      adminLogin(
        storedData.adminId,
        storedData.adminToken,
        new Date(storedData.expiration)
      );
    }
  }, [adminLogin]);

  //admin

  //employee

  const [employeeToken, setEmployeeToken] = useState(false);
  const [employeeTokenExpirationDate, setEmployeeTokenExpirationDate] =
    useState();
  const [employeeId, setEmployeeId] = useState(false);

  const employeeLogin = useCallback((uid, token, expirationDate) => {
    setEmployeeToken(token);
    setEmployeeId(uid);

    const employeeTokenExpirationDate =
      expirationDate ||
      new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 10000);
    setEmployeeTokenExpirationDate(employeeTokenExpirationDate);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        adminId: uid,
        employeeToken: token,
        expiration: employeeTokenExpirationDate.toISOString(),
      })
    );
  }, []);

  const employeeLogout = useCallback(() => {
    setEmployeeToken(null);
    setEmployeeTokenExpirationDate(null);
    setEmployeeId(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    if (employeeToken && employeeTokenExpirationDate) {
      const remainingTime =
        employeeTokenExpirationDate.getTime() - new Date().getTime();
      employeeLogoutTimer = setTimeout(employeeLogout, remainingTime);
    } else {
      clearTimeout(employeeLogoutTimer);
    }
  }, [employeeToken, employeeLogout, employeeTokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.employeeToken &&
      new Date(storedData.expiration) > new Date()
    ) {
      employeeLogin(
        storedData.employeeId,
        storedData.employeeToken,
        new Date(storedData.expiration)
      );
    }
  }, [employeeLogin]);

  //employee

  //customer

  const [customerToken, setCustomerToken] = useState(false);
  const [customerTokenExpirationDate, setCustomerTokenExpirationDate] =
    useState();
  const [customerId, setCustomerId] = useState(false);

  const customerLogin = useCallback((uid, token, expirationDate) => {
    setCustomerToken(token);
    setCustomerId(uid);

    const customerTokenExpirationDate =
      expirationDate ||
      new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 10000);
    setCustomerTokenExpirationDate(customerTokenExpirationDate);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        customerId: uid,
        customerToken: token,
        expiration: customerTokenExpirationDate.toISOString(),
      })
    );
  }, []);

  const customerLogout = useCallback(() => {
    setCustomerToken(null);
    setCustomerTokenExpirationDate(null);
    setCustomerId(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    if (customerToken && customerTokenExpirationDate) {
      const remainingTime =
        customerTokenExpirationDate.getTime() - new Date().getTime();
      customerLogoutTimer = setTimeout(customerLogout, remainingTime);
    } else {
      clearTimeout(customerLogoutTimer);
    }
  }, [customerToken, customerLogout, customerTokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.customerToken &&
      new Date(storedData.expiration) > new Date()
    ) {
      customerLogin(
        storedData.customerId,
        storedData.customerToken,
        new Date(storedData.expiration)
      );
    }
  }, [customerLogin]);

  //customer

  const [backHome, setBackHome] = useState();

  return {
    adminToken,
    adminLogin,
    adminLogout,
    adminId,
    employeeToken,
    employeeLogin,
    employeeLogout,
    employeeId,
    customerToken,
    customerLogin,
    customerLogout,
    customerId,
    backHome,
    setBackHome,
  };
};
