import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
  useDeferredValue,
} from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import {
  IoArrowBackOutline,
  IoAttachOutline,
  IoCheckboxOutline,
  IoCloseOutline,
  IoHomeOutline,
  IoPowerOutline,
} from "react-icons/io5";
import LoadingSpinner from "../../shared/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/UIElements/ErrorModal";

// import uuid from "uuid";

const StorySubmital = () => {
  const auth = useContext(AuthContext);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const { register, control, handleSubmit, reset, watch, setValue } = useForm({
    // defaultValues: {
    //   hostArray: [
    //     {
    //       //title: "",
    //       paragraph: "",
    //       //picture: null,
    //     },
    //   ],
    //   //   imggrab: [
    //   //     {
    //   //       img: null,
    //   //     },
    //   //   ],
    // },
  });
  //   const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
  //     {
  //       control,
  //       name: "hostArray",
  //     }
  //   );
  //   const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
  //     {
  //       control,
  //       name: "hostArray",
  //     }
  //   );

  const arr1 = useFieldArray({
    control: control,

    name: "hostArray",
  });

  const arr2 = useFieldArray({
    control: control,

    name: "tags",
  });

  //   const {
  //     register,
  //     handleSubmit,
  //     reset,
  //     trigger,
  //     setError,

  //     setValue,
  //     control,
  //     formState: { isSubmitSuccessful },
  //   } = useForm();

  const [categoryTitle, setCategoryTitle] = useState();

  const [categoryId, setCategoryId] = useState();

  const [subcat, setSubcat] = useState();
  const [subcat2, setSubcat2] = useState();

  //   const arr1 = useFieldArray({
  //     control: control,

  //     name: "hostArray",
  //   });

  const [categories, setCategories] = useState();

  const fetchCategories = useCallback(async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getCategories`
      );

      setCategories(responseData.findCategories);
    } catch (err) {
      console.log(err);
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const [aCategory, setACategory] = useState();

  const [stories, setStories] = useState();

  const parseCategories = async (key) => {
    console.log(key.target.id);

    setACategory("");
    setSubcat("");

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/getSingleCategory/${key.target.id}`
      );

      console.log(responseData);
      setCategoryTitle(responseData.findCategory.name);
      setCategoryId(responseData.findCategory._id);

      console.log(responseData.findCategory._id);
      setACategory(responseData.findCategory);
      setStories(responseData.findStories);
    } catch (err) {}
  };

  const getSubcat = (key) => {
    console.log(key.target.id);
    setSubcat(key.target.id);
    setSubcat2(key.target.id);
  };

  const [pictureName, setPictureName] = useState();

  //const deferedRef = useDeferredValue(pictureName);

  const fileChange = (event) => {
    //console.log(event.target.files);
    console.log(event.target.files[0]);

    //console.log(event.target.parentNode.childrenNodes[2].value = "hello");
    //event.target.parentNode.children[2].value = "hello";
    // event.target.parentNode.children[2].value = event.target.files[0].name;

    // setPictureName(event.target.files[0].name);
    setValue(
      event.target.parentNode.children[1].name,
      `${event.target.files[0].size}-${event.target.files[0].name}`
    );
    console.log(event.target.files[0]);

    // event.target.parentNode.children[2] =
    //   event.target.files[0].name;
    // console.log(event.target.parentNode.children[2]);
    // console.log(event.target.parentNode.children[2].value);

    // event.target.parentNode.children[2].hostArray[0].picture.value =
    //   event.target.files[0].name;

    //console.log(typeof event.target.parentNode.children[2].value);

    //const hello = "hi";

    //console.log(typeof hello);
  };

  //   const fileUpload = useRef(null);
  //   const uploadProfilePic = (e) => {
  //     console.log(e.target.files[0]);
  //   };

  //   const handleUpload = () => {
  //     fileUpload.current.click();
  //   };

  const submitStory = async (data) => {
    console.log(data);
    console.log(data.tags);
    for (let i = 0; i < data.hostArray.length; i++) {
      // formData.append(i, data.pics[i].picture[0]);
      //console.log(data.hostArray[i].img[0]);
      if (data.hostArray[i].img) {
        console.log(data.hostArray[i].img[0]);
      }
    }
    try {
      const formData = new FormData();

      const picsContainer = [];

      for (let i = 0; i < data.hostArray.length; i++) {
        // formData.append(i, data.pics[i].picture[0]);
        //console.log(data.hostArray[i].img[0]);
        if (data.hostArray[i].img) {
          console.log(data.hostArray[i].img[0]);

          //fileReader.readAsDataURL(fileGreeting);
          //picsContainer.push(data.hostArray[i].img[0]);
          formData.append("photos", data.hostArray[i].img[0]);
        }
      }

      for (let i = 0; i < data.hostArray.length; i++) {
        // formData.append(i, data.pics[i].picture[0]);
        //console.log(data.hostArray[i].img[0]);
        if (data.hostArray[i].reel) {
          console.log(data.hostArray[i].reel[0]);

          //fileReader.readAsDataURL(fileGreeting);
          //picsContainer.push(data.hostArray[i].img[0]);
          formData.append("video", data.hostArray[i].video[0]);
        }
      }

      for (let i = 0; i < data.hostArray.length; i++) {
        // formData.append(i, data.pics[i].picture[0]);
        console.log(data.hostArray[i]);
        // if (data.hostArray[i].img[0]) {
        //   console.log(data.hostArray[i].img[0].name);
        // }
      }
      formData.append("coverPhoto", data.coverPhoto[0]);
      formData.append("story", JSON.stringify(data.hostArray));
      formData.append("headline", data.headline);
      if (data.subcategory) {
        formData.append("subcategory", data.subcategory);
      }
      formData.append("category", data.category);

      formData.append("tags", JSON.stringify(data.tags));
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/createAStory`,
        "POST",
        formData,

        {
          //"Content-Type": "application/json",
          Authorization: "Bearer " + auth.adminToken,
        }
      );
    } catch (err) {}
  };

  return (
    <div className="storySubmital">
      <div className="dashboard">
        {isLoading && <LoadingSpinner asOverlay />}

        {<ErrorModal onClear={clearError} error={error} />}
        {categories && (
          <div>
            <div className="homePage-categories-bar">
              {categories.map((x) => (
                <div
                  id={x.name}
                  onClick={parseCategories}
                  className="homePage-categories-title"
                >
                  {x.name}
                </div>
              ))}
            </div>
            {aCategory && (
              <div className="homePage-categories-bar2">
                {aCategory.subcategories.map((x) => (
                  <div
                    onClick={getSubcat}
                    id={x.name}
                    className="homePage-categories-title2"
                  >
                    {x.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <form onSubmit={handleSubmit(submitStory)}>
          <div className="dashboard-pPics-uncover">
            <label className="dashboard-pPics-title">article: </label>
            <br />
            <label>headline:</label>
            <br />
            <input {...register(`headline`)} type="text" />
            <br />
            <br />
            <label>cover photo:</label>
            <br />
            <input
              {...register(`coverPhoto`)}
              type="file"
              accept=".jpg,.png,.jpeg"
            />
            <br />

            {categoryTitle && categoryId && (
              <div>
                <label>{categoryTitle}</label>
                <input {...register(`category`)} value={categoryId} />
                {subcat && (
                  <div>
                    <label>{subcat}</label>
                    <input {...register(`subcategory`)} value={subcat} />
                  </div>
                )}
              </div>
            )}

            <span
              className="dashboard-coverPhoto-iconFont"
              type="button"
              onClick={() => arr1.append({ title: "true", words: "" })}
            >
              title
            </span>
            <br />
            <span
              className="dashboard-coverPhoto-iconFont"
              type="button"
              onClick={() => arr1.append({ code: "true", words: "" })}
            >
              code
            </span>
            <br />
            <span
              className="dashboard-coverPhoto-iconFont"
              type="button"
              //id="paragraph"
              onClick={() => arr1.append({ paragraph: "true", words: "" })}
            >
              paragraph
            </span>
            <br />
            <span
              className="dashboard-coverPhoto-iconFont"
              type="button"
              //id="paragraph"
              onClick={() =>
                arr1.append({ photo: "true", words: "", img: null })
              }
            >
              photo
            </span>
            <br />
            <span
              className="dashboard-coverPhoto-iconFont"
              type="button"
              //id="paragraph"
              onClick={() =>
                arr1.append({ reel: "true", words: "", video: null })
              }
            >
              video
            </span>
            <br />
            <span
              className="dashboard-coverPhoto-iconFont"
              type="button"
              //id="paragraph"
              onClick={() => arr2.append({ name: "" })}
            >
              tags
            </span>
            {/* <span
            className="dashboard-coverPhoto-iconFont"
            type="button"
            onClick={() => arr1.append({ picture: null })}
          >
            picture
          </span> */}
            {/* <span
          className="dashboard-coverPhoto-iconFont"
          type="button"
          onClick={() => arr1.append({ picture: null })}
        >
          <IoAttachOutline />
        </span> */}
          </div>

          {arr1.fields.map((item, index) => (
            <li className="dashboard-pPics-listItems" key={item.id}>
              {/* {item.paragraph !== "" && item.title !== "" && (
              <div>
                <label>picture:</label>

                <input
                  onInput={setValue(`hostArray.${index}.picture`, "hi")}
                  onInput={fileChange}
                    defaultValue={undefined}
                  {...register(`imggrab.${index}.img`)}
                  ref={register(`imggrab[${index}].img`)}
                  name={`imggrab[${index}].img`}
                  type="file"
                  accept=".jpg,.png,.jpeg"
                  {...register(`imggrab[${index}].img`)}
                />
                <input
                  //ref={register(`hostArray[${index}].picture`)}
                  //name={`hostArray[${index}].picture`}
                  //   {...register()}
                  {...register(`hostArray[${index}].picture`)}
                />
              </div>
            )}
            {item.paragraph === "" && (
              <div>
                <label>paragraph:</label>
                <textarea
                  key={item.id}
                  //name={`hostArray[${index}].paragraph`}
                  //ref={register()}
                  //ref={register(`hostArray[${index}].paragraph`)}
                  {...register(`hostArray[${index}].paragraph`)}
                  defaultValue={item.paragraph}
                />
              </div>
            )}
            {item.title === "" && (
              <div>
                <label>title:</label>
                <input
                  key={item.id}
                  name={`hostArray[${index}].title`}
                  //ref={register(`hostArray[${index}].title`)}
                  //ref={register()}
                  //type="text"
                  {...register(`hostArray[${index}].title`)}
                  defaultValue={item.title}
                />
              </div>
            )}

            <button
              className="dashboard-xButton"
              type="button"
              onClick={() => arr1.remove(index)}
            >
              <IoCloseOutline />
            </button>
            {/* <Controller
                  render={({ field }) => <input {...field} />}
                  name={`test.${index}.lastName`}
                  control={control}
                /> */}
              {/* <input
              //   onInput={setValue(`hostArray.${index}.picture`, "hi")}
              //   onInput={fileChange}
              defaultValue={undefined}
              {...register(`imggrab.${index}.img`)}
              ref={register(`imggrab[${index}].img`)}
              name={`imggrab[${index}].img`}
              type="file"
              accept=".jpg,.png,.jpeg"
              {...register(`imggrab[${index}].img`)}
            /> */}

              {item.title === "true" && (
                <div>
                  title
                  <input
                    type="radio"
                    //key={item.id}
                    //name={`hostArray[${index}].paragraph`}
                    //ref={register()}
                    //ref={register(`hostArray[${index}].paragraph`)}
                    {...register(`hostArray[${index}].title`)}
                    defaultValue={item.title}
                  />
                </div>
              )}
              {item.code === "true" && (
                <div>
                  code
                  <input
                    type="radio"
                    //key={item.id}
                    //name={`hostArray[${index}].paragraph`}
                    //ref={register()}
                    //ref={register(`hostArray[${index}].paragraph`)}
                    {...register(`hostArray[${index}].code`)}
                    defaultValue={item.code}
                  />
                </div>
              )}
              {item.paragraph === "true" && (
                <div>
                  paragraph
                  <input
                    type="radio"
                    //key={item.id}
                    //name={`hostArray[${index}].paragraph`}
                    //ref={register()}
                    //ref={register(`hostArray[${index}].paragraph`)}
                    {...register(`hostArray[${index}].paragraph`)}
                    defaultValue={item.paragraph}
                  />
                </div>
              )}
              {item.reel === "true" && (
                <div>
                  video
                  <input
                    type="radio"
                    //key={item.id}
                    //name={`hostArray[${index}].paragraph`}
                    //ref={register()}
                    //ref={register(`hostArray[${index}].paragraph`)}
                    {...register(`hostArray[${index}].reel`)}
                    defaultValue={item.reel}
                  />
                </div>
              )}

              {item.reel === "true" && (
                <div>
                  <input
                    type="file"
                    accept=".mp4"
                    {...register(`hostArray[${index}].video`)}
                    onInput={fileChange}
                  />
                  <input {...register(`hostArray[${index}].words`)} />
                </div>
              )}
              {item.photo === "true" && (
                <div>
                  photo
                  <input
                    type="radio"
                    //key={item.id}
                    //name={`hostArray[${index}].paragraph`}
                    //ref={register()}
                    //ref={register(`hostArray[${index}].paragraph`)}
                    {...register(`hostArray[${index}].photo`)}
                    defaultValue={item.photo}
                  />
                </div>
              )}

              {item.photo === "true" && (
                <div>
                  <input
                    type="file"
                    accept=".jpg,.png,.jpeg"
                    {...register(`hostArray[${index}].img`)}
                    onInput={fileChange}
                  />
                  <input {...register(`hostArray[${index}].words`)} />
                </div>
              )}
              {(item.photo || item.reel) !== "true" && (
                <textarea
                  //key={item.id}
                  //name={`hostArray[${index}].paragraph`}
                  //ref={register()}
                  //ref={register(`hostArray[${index}].paragraph`)}

                  {...register(`hostArray[${index}].words`)}
                  defaultValue={item.words}
                />
              )}
              {/* <textarea
              key={item.id}
              //name={`hostArray[${index}].paragraph`}
              //ref={register()}
              //ref={register(`hostArray[${index}].paragraph`)}
              {...register(`hostArray[${index}].title`)}
              defaultValue={item.paragraph}
            /> */}
              <button
                className="dashboard-xButton"
                type="button"
                onClick={() => arr1.remove(index)}
              >
                <IoCloseOutline />
              </button>
            </li>
          ))}

          {arr2.fields.map((item, index) => (
            <div>
              <input
                //key={item.id}
                //name={`hostArray[${index}].paragraph`}
                //ref={register()}
                //ref={register(`hostArray[${index}].paragraph`)}

                {...register(`tags[${index}].name`)}
                defaultValue={item.name}
              />

              <button
                className="dashboard-xButton"
                type="button"
                onClick={() => arr2.remove(index)}
              >
                <IoCloseOutline />
              </button>
            </div>
          ))}

          <button>submit</button>
        </form>
      </div>
    </div>
  );
};

export default StorySubmital;
