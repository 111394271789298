import React, { useContext, useState } from "react";

import { useHttpClient } from "../shared/hooks/http-hook";

import logo from "../imgs/jop-logo.jpg";
import { useForm } from "react-hook-form";
import { useAuth } from "../shared/hooks/auth-hook";
import { AuthContext } from "../shared/context/auth-context";

const Portal = () => {
  //   const [loginType, setLoginType] = useState(false);

  const auth = useContext(AuthContext);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const { register, handleSubmit } = useForm();

  const loginFunc = async (data) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/login`,
        "POST",
        JSON.stringify({
          username: data.username,
          password: data.password,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      console.log(responseData);
      auth.adminLogin(responseData.adminId, responseData.adminToken);
    } catch (error) {}
  };

  return (
    <div className="portal">
      {/* <div className="homePage-navBar">
        <img
          src={logo}
          alt="The Journal of Press"
          className="homePage-navBar-logo"
        />
        <p className="homePage-navBar-logo">
          The Journal
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; of Press
        </p>
      </div> */}
      <div className="portal-block">
        {/* {!loginType && (
          <div>
            <div className="inlineBlock portal-block-choice">CEO</div>
            <div className="inlineBlock portal-block-choice">Press</div>
          </div>
        )} */}

        <div className="portal-block-form">
          <form onSubmit={handleSubmit(loginFunc)}>
            <label>username:</label>
            <br />
            <input {...register("username")} />
            <br />
            <label>password:</label>
            <br />
            <input {...register("password")} />
            <button>submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Portal;
